<template>
  <div class="demonstration-field-add">
    <HeaderNavigation type="book" />
    <Form ref="form" :rows="rows" v-model="detail" />
    <Navigation ref="navigation" :show-navigation="false" />
    <FooterButton :loading="loading" @click="submit">保存</FooterButton>
  </div>
</template>
<script>
import HeaderNavigation from "@/components/header/headerWithNavigation";
import Form from "@/components/common/form/index.vue";
import FooterButton from "@/components/common/button/FooterButton.vue";
import Navigation from "@/components/navigation/index.vue";
import {
  demonstrationFieldApi,
  terminalOutletsApi,
  bigGrowersApi,
} from "@/utils/api.js";
import { getDealerId, getDealerName } from "@/utils/user.js";
import * as util from "@/utils/util.js";
export default {
  components: {
    HeaderNavigation,
    Form,
    FooterButton,
    Navigation,
  },
  data() {
    return {
      loading: false,
      isHideTerminalField: true,
      isHideBigGrowersField: true,
      detail: {
        dealerName: getDealerName(),
      },
    };
  },
  activated() {
    this.detail = { dealerName: getDealerName() };
    this.isHideBigGrowersField = true;
    this.isHideTerminalField = true;
  },
  computed: {
    rows() {
      return [
        {
          key: "modelFieldType",
          label: "示范田类型",
          required: true,
          type: "picker",
          dictType: "modelfield_type",
          handleChange: (value) => {
            // 重置所有信息
            this.isHideTerminalField = true;
            this.isHideBigGrowersField = true;
            this.$set(this.detail, "kjChannel", "");
            if (value === "2") {
              this.isHideTerminalField = false;
            }
            if (value === "3") {
              this.isHideBigGrowersField = false;
            }
          },
        },
        {
          key: "modelFieldName",
          label: "示范田名称",
          required: true,
          type: "input",
          gap: true,
        },
        {
          key: "dealerName",
          label: "所属经销商",
          required: true,
          gap: true,
        },
        {
          key: "kjChannel",
          label: !this.isHideTerminalField ? "所属终端" : "种植大户名称",
          type: "picker",
          dictType: !this.isHideTerminalField ? "terminal" : "grower",
          required: true,
          hide: this.isHideTerminalField && this.isHideBigGrowersField,
        },
        {
          key: "mobile",
          label: "手机",
          required: true,
          type: "input",
          inputType: "tel",
        },
        {
          key: "kjRegion",
          label: "所属市县",
          required: true,
          type: "picker",
          dictType: "region",
          valueString: "id",
          labelString: "name",
          defaultLabel: "region",
          handleChange: () => {
            this.$refs.form.$refs.kjTowninfo[0].fetchTown(this.detail.kjRegion);
            this.$refs.form.$refs.kjTowninfo[0].reset();
            this.$refs.form.$refs.kjVillage[0].reset();
            this.$set(this.detail, "towninfo", "");
            this.$set(this.detail, "village", "");
          },
        },
        {
          key: "kjTowninfo",
          label: "所属乡镇",
          required: true,
          type: "picker",
          dictType: "town",
          valueString: "id",
          labelString: "name",
          defaultLabel: "towninfo",
          handleChange: () => {
            this.$refs.form.$refs.kjVillage[0].fetchVillage(
              this.detail.kjTowninfo
            );
            this.$refs.form.$refs.kjVillage[0].reset();
            this.$set(this.detail, "village", "");
          },
          beforeOpenCheck: () => {
            if (!this.detail.kjRegion) {
              return "请选择市县";
            }
          },
        },
        {
          key: "kjVillage",
          label: "所属村",
          type: "picker",
          gap: true,
          dictType: "village",
          valueString: "id",
          labelString: "name",
          defaultLabel: "village",
          beforeOpenCheck: () => {
            if (!this.detail.kjRegion) {
              return "请选择市县";
            }
            if (!this.detail.kjTowninfo) {
              return "请选择乡镇";
            }
          },
        },
        {
          key: "plantCover",
          label: "示范作物",
          required: true,
          type: "picker",
          dictType: "plantSelect",
        },
        {
          key: "plantNum",
          label: "种植亩数",
          required: true,
          type: "input",
          inputType: "number",
        },
        {
          key: "plantBrand",
          label: "示范产品",
          required: true,
          type: "input",
        },
        {
          key: "perNum",
          label: "每亩用量",
          type: "input",
          inputType: "number",
          required: true,
          gap: true,
        },
        {
          key: "businessMan",
          label: "属地化业务员",
          type: "picker",
          required: true,
          gap: true,
          dictType: "salesman",
        },
      ];
    },
    watchProp1() {
      return this.detail.kjChannel;
    },
  },
  watch: {
    async watchProp1() {
      // 终端
      if (this.detail.modelFieldType === "2" && this.detail.kjChannel) {
        const res = await terminalOutletsApi.distDetail({
          id: this.detail.kjChannel,
        });
        const data = res.data;
        this.$set(this.detail, "mobile", data.mobile);
        this.$set(this.detail, "kjRegion", data.regionId);
        this.$set(this.detail, "kjTowninfo", data.towninfoId);
        this.$set(this.detail, "kjVillage", data.villageId);
        this.$set(this.detail, "region", data.region);
        this.$set(this.detail, "towninfo", data.town);
        this.$set(this.detail, "village", data.village);
        this.$refs.form.$refs.kjTowninfo[0].fetchTown(this.detail.kjRegion);
        this.$refs.form.$refs.kjVillage[0].fetchVillage(this.detail.kjTowninfo);
      }
      // 种植大户
      if (this.detail.modelFieldType === "3" && this.detail.kjChannel) {
        const res = await bigGrowersApi.distDetail({
          id: this.detail.kjChannel,
        });
        const data = res.data;
        this.$set(this.detail, "mobile", data.bigfarmersTel);
        this.$set(this.detail, "kjRegion", data.kjRegion);
        this.$set(this.detail, "kjTowninfo", data.kjTowninfo);
        this.$set(this.detail, "kjVillage", data.kjVillage);
        this.$set(this.detail, "region", data.kjRegionName);
        this.$set(this.detail, "towninfo", data.kjTowninfoName);
        this.$set(this.detail, "village", data.kjVillageName);
        this.$refs.form.$refs.kjTowninfo[0].fetchTown(this.detail.kjRegion);
        this.$refs.form.$refs.kjVillage[0].fetchVillage(this.detail.kjTowninfo);
      }
    },
  },
  methods: {
    async submit() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.loading = true;
      const {
        modelFieldName,
        modelFieldType,
        mobile,
        kjRegion,
        kjTowninfo,
        kjVillage,
        plantNum,
        plantBrand,
        perNum,
        plantCover,
        businessMan,
        kjChannel,
      } = this.detail;
      let kjChannelName = "";
      if (!this.isHideTerminalField) {
        kjChannelName = await util.fetchNameById("terminal", kjChannel);
      }
      if (!this.isHideBigGrowersField) {
        kjChannelName = await util.fetchNameById("grower", kjChannel);
      }
      try {
        await demonstrationFieldApi.distSave({
          kjChannel: kjChannel || "",
          kjChannelName,
          plantMatch: "",
          plantCover: plantCover || "",
          businessMan: businessMan || "",
          modelFieldName: modelFieldName || "",
          modelFieldType: modelFieldType || "",
          mobile: mobile || "",
          kjRegion: kjRegion || "",
          kjTowninfo: kjTowninfo || "",
          kjVillage: kjVillage || "",
          plantNum: plantNum || "",
          plantBrand: plantBrand || "",
          perNum: perNum || "",
          "kjDealer.dealerId": getDealerId(),
          position: this.$refs.navigation.value,
          coordinate: `${this.$refs.navigation.currentLocation.lng},${this.$refs.navigation.currentLocation.lat}`,
        });
        this.$toast("新增成功");
        this.$router.back();
      } catch (err) {
        this.$toast(err.message);
      }
      this.loading = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.demonstration-field-add {
  width: 100%;
  min-height: 100vh;
  background-color: #fafbfc;
  padding-bottom: 56px;
  box-sizing: border-box;
}
</style>
