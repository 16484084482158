<template>
  <div class="demonstration-field-detail">
    <HeaderNavigation class="mb-12" />
    <Form
      ref="form"
      :rows="
        activeName === 'DemonstrationFieldShidanliDetail' ? rows : personRows
      "
      v-model="detail"
    />
    <Navigation class="mb-12" :navigation-item="navigationItem" />
    <RowLink :links="links" />
  </div>
</template>
<script>
import HeaderNavigation from "@/components/header/headerWithNavigation";
import Form from "@/components/common/form/index.vue";
import Navigation from "@/components/navigation/index.vue";
import RowLink from "@/components/select/rowLink.vue";
import { demonstrationFieldApi } from "@/utils/api.js";
export default {
  components: {
    Form,
    Navigation,
    RowLink,
    HeaderNavigation,
  },
  data() {
    return {
      loading: false,
      id: "",
      activeName: "",
      detail: {},
    };
  },
  computed: {
    navigationItem() {
      const point = this.detail.coordinate || "";
      const [lng, lat] = point.split(",");
      return { lng, lat, position: this.detail.position };
    },
    rows() {
      return [
        {
          key: "modelFieldTypeName",
          label: "示范田类型",
        },
        {
          key: "modelFieldName",
          label: "示范田名称",
        },
        {
          key: "dealerName",
          label: "所属经销商",
          gap: true,
        },
        {
          key: "brand",
          label: "品牌",
        },
        {
          key: "mobile",
          label: "联系方式",
          gap: true,
        },
        {
          key: "region",
          label: "所属市县",
        },
        {
          key: "towninfo",
          label: "所属乡镇",
        },
        {
          key: "village",
          label: "所属村",
          gap: true,
        },
        {
          key: "businessManName",
          label: "属地业务员",
        },
        // {
        //   key: "businessMasterName",
        //   label: "业务主任",
        //   gap: true,
        // },
        {
          key: "modelFieldStatusLabel",
          label: "示范状态",
        },
        {
          key: "plantCover",
          label: "示范作物",
        },
        {
          key: "plantNum",
          label: "示范亩数",
          unit: "亩",
        },
        {
          key: "plantMatch",
          label: "肥料配比",
        },
        {
          key: "perNum",
          label: "每亩用量",
          unit: "KG",
          gap: true,
        },
      ];
    },
    personRows() {
      return [
        {
          key: "modelFieldTypeName",
          label: "示范田类型",
        },
        {
          key: "modelFieldName",
          label: "示范田名称",
        },
        {
          key: "dealerName",
          label: "所属经销商",
          gap: true,
        },
        {
          key: "kjChannelName",
          label:
            this.detail.modelFieldType === "2" ? "终端名称" : "种植大户名称",
          hide: this.detail.modelFieldType === "1",
        },
        {
          key: "mobile",
          label: "联系方式",
          gap: true,
        },
        {
          key: "region",
          label: "所属市县",
        },
        {
          key: "towninfo",
          label: "所属乡镇",
        },
        {
          key: "village",
          label: "所属村",
          gap: true,
        },
        {
          key: "businessManName",
          label: "属地业务员",
          gap: true,
        },
        {
          key: "modelFieldStatusLabel",
          label: "示范状态",
        },
        {
          key: "plantCover",
          label: "示范作物",
        },
        {
          key: "plantNum",
          label: "示范亩数",
          unit: "亩",
        },
        {
          key: "plantBrand",
          label: "示范产品",
        },
        {
          key: "perNum",
          label: "每亩用量",
          unit: "KG",
          gap: true,
        },
      ];
    },
    links() {
      if (this.activeName === "DemonstrationFieldShidanliDetail") {
        return [
          {
            label: "近期拜访记录",
            path: `/visit/list?type=demonstrationField&id=${this.id}&tab=shidanli`,
          },
        ];
      } else {
        return [
          {
            label: "近期拜访记录",
            path: `/visit/list?type=demonstrationField&id=${this.id}&tab=ziyou`,
          },
        ];
      }
    },
  },
  activated() {
    this.activeName = this.$route.name;
    this.id = this.$route.params.id;
    this.fetchDetail();
  },
  methods: {
    async fetchDetail() {
      let response = "";
      if (this.activeName === "DemonstrationFieldShidanliDetail") {
        response = await demonstrationFieldApi.detail({
          id: this.id,
        });
      } else {
        response = await demonstrationFieldApi.distDetail({
          id: this.id,
        });
      }
      this.detail = {
        ...response.data,
        dealerName: response.data.kjDealer.dealerName,
        modelFieldStatusLabel:
          response.data.modelFieldStatus === "0" ? "示范中" : "已结束",
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.demonstration-field-detail {
  width: 100%;
  min-height: 100vh;
  background-color: #fafbfc;
  padding-bottom: 40px;
}
</style>
